<template>    

    <v-skeleton-loader type="opti-input" v-if="compSkeleton"></v-skeleton-loader>

    <v-text-field v-else 
        ref="vtf"
        :class="compClass"
        :dense="dense"
        :value="dvalue"
        :disabled="compDisabled"
        :rules="rules"
        :placeholder="compPlaceholder"
        :label="label"
        hide-details 
        outlined
        :prepend-inner-icon="prependInnerIcon"
        @input="dvalue = $event"
        @keyup.enter="onEnter"
        @focus="onFocus"

        >
        <template v-if="suggestions.length || $slots.append" v-slot:append class="mt-0"> 
            <slot name="append"></slot>
            
            <v-menu offset-y v-if="suggestions.length">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn icon small v-bind="attrs" v-on="on">
                        <Icon small :type="compIconSuggestions"></Icon>
                    </v-btn>
                </template>
                <v-list dense>
                    <v-list-item v-for="(item, index) in suggestionLabels" :key="index" link >
                        <v-list-item-title @click="onSuggestion(item)">{{ item }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu> 

        </template>
        <template v-slot:append-outer> 
            <slot name="append-outer"></slot>
        </template>

    </v-text-field>

</template>

<script>
//  import TextField from '@controls/input/TextField'
//
// Usage: 
//   <TextField v-model="name"></TextField>
// or
//   <TextField :value="line.ihp_name"  @input="e => onSet('ihp_name', e)"></TextField>
// To use suggestions: 
//   <TextField v-model="line.name" suggestions="[{label: '%BETAALDAGEN%', value: '%BETAALDAGEN%'},{label: 'Datum', value: '2021-05-11'}]"> 
//

import Icon from '@controls/icons/Icon'


export default {
        name: 'TextField',
        components: {
            Icon
        },
        props: {
            dense: {
                type: [Boolean],
                default: true,
            },
            value: {
                type: [String, Number]
            },
            disabled: {
                type: [Boolean]
            },
            placeholder: {
                type: [String]
            },
            label: {
                type: [String]
            },
            rules: {
                type: [Array]
            },
            noBorder: {
                type: [Boolean]
            },
            suggestions: {
                type: [Object, Array],
                default: () => []
            },            
            iconSuggestions: {
                type: [String]
            },
            prependInnerIcon: {
                type: [String]
            },        
            maxLength: {
                type: [String, Number],
                default: null
            },        
            skeleton: false,
            model: {
                type: [Object]
            }
        },
        data () {
            return {                            
            }
        },
        methods: {
            clear: function() {
                this.dvalue = null;
            },
            onFocus: function() {
                // Need to do this at focus as the input element is rendered and not rendered depending on the skeleton property
                if (Number(this.maxLength)) {
                    const inputElement = this.$refs?.vtf?.$refs?.input;
                    if (inputElement) {
                        inputElement.maxLength = this.maxLength
                    }
                }

            },
            // Set the data value. Method for possible manipulation
            onEnter: function() {
                this.$emit('enter');              
            },
            onSuggestion(v) {
                var value = "";
                var vari = this.suggestions.find ( x => x.label == v);
                if (vari) {
                    value = vari.value || "";
                } else {
                    value = v;
                }
                this.dvalue = `${this.dvalue} ${value}`;
            }

        },
        computed: {
            dvalue: {
                get() {
                    return this.value
                },
                set(value) {
                    this.$emit('input', value)
                }	
            },
            compSkeleton: function() {
                if (this.skeleton) {
                    return true;
                }
                if (!this.model) {
                    return false;
                }
                return this.model.isDataLoading;
            },
            compDisabled: function() {
                if (this.disabled) {
                    return true;
                }
                if (!this.model) {
                    return false;
                }
                return this.model.disabled;
            },
            compClass: function() {
                var border = this.noBorder ? "no-border" : ""; 
                return `${border}`;
            },
            compIconSuggestions: function() {
                return this.iconSuggestions || 'dropdown-thin';
            },
            // Label overrides the placeholder.
            compPlaceholder: function() {
                if (this.label) {
                    return null;
                }
                return this.placeholder;
            },

            // Get the labels to use for the suggestions. 
            // The suggestions can come in two ways 
            // 1) ==> ['VARI1', 'VARI2', 'VARI3']
            // 2) ==> [{label: 'VARI1', 'value: "Een mooie variable numero 1"}, {label: 'VARI2', 'value: "Een fantastische variable numero 2"}]
            // See the code below.
            suggestionLabels: function() {
                var arr = [];
                for (var n =0;n< this.suggestions.length; n++) {
                    if (!this.suggestions[n].label) {
                        arr.push(this.suggestions[n]);
                    } else {
                        arr.push(this.suggestions[n].label);
                    }
                }
                return arr;
            }
        },
    }
</script>
